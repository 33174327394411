import { resolveComponent as _resolveComponent, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode } from "vue";
import { provide, ref, nextTick } from 'vue';
export default {
  __name: 'App',
  setup(__props) {
    const isRouterActive = ref(true);
    provide('reload', () => {
      isRouterActive.value = false;
      nextTick(() => {
        isRouterActive.value = true;
      });
    });
    return (_ctx, _cache) => {
      const _component_router_view = _resolveComponent("router-view");
      return isRouterActive.value ? (_openBlock(), _createBlock(_component_router_view, {
        key: 0
      })) : _createCommentVNode("", true);
    };
  }
};