import { createRouter, createWebHashHistory } from 'vue-router'

const routes = [
  {
    path: '/',
    name: 'home',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/HomePage.vue')
  },
  {
    //投诉与建议
    path: '/comandsuglist',
    name: 'comandsuglist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ComAndSug/ComAndSugList.vue')
  }
  ,
  {
    //投诉与建议
    path: '/comandsug',
    name: 'comandsug',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/ComAndSug/ComAndSug.vue')
  },
  {
    //周报列表页面
    path: '/UIProjectReportDirectorList',
    name: 'UIProjectReportDirectorList',
    component: () => import('../views/UIProjectReportDirector/UIProjectReportDirectorList.vue')
  },
  {
    //月报列表页面（项目简报列表）
    path: '/UIProjectReportList',
    name: 'UIProjectReportList',
    component: () => import('../views/UIProjectReport/UIProjectReportList.vue')
  },
 
  //需求管理
  {
    path:'/DemandRemandUser',
    name:'DemandRemandUser',
    component: () => import('../views/DemandManagement/DemandRemandUser.vue')
  },
  //个人需求跟着
  {
    path:'/DemandPersonNeed',
    name:'DemandPersonNeed',
    component: () => import('../views/DemandManagement/DemandPersonNeed.vue')
  },
  // 需求统计
  {
    path:'/DemandStatistics',
    name:'DemandStatistics',
    component: () => import('../views/DemandManagement/DemandStatistics.vue')
  }
  ,
  {
    //开工报告
    path: '/workstartreportlist',
    name: 'workstartreportlist',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/WorkStartReport/WorkStartReportList.vue')
  }
  ,
  {
    //报告预览
    path: '/reportview',
    name: 'reportview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/ReportView.vue')
  }
  ,
  {
    //pdf预览
    path: '/pdfview',
    name: 'pdfview',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../components/PdfView.vue')
  }
  ,
  {
    //发票
    path: '/billManage',
    name: 'billManage',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BillManage/BillManage.vue')
  }
  ,
  {
    //发票ocr
    path: '/ocrBill',
    name: 'ocrBill',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/BillManage/OcrBill.vue')
  }
  ,
  {
    //用章申请
    path: '/inChapter',
    name: 'inChapter',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import(/* webpackChunkName: "about" */ '../views/InChapter/InChapter.vue')
  }

  ,
  {
    //用章申请列表
    path: '/inchapterList',
    name: 'inchapterList',
    component: () => import(/* webpackChunkName: "about" */ '../views/InChapter/InchapterList.vue')
  }
  ,
  {
    //企业微信用户
    path: '/userWxList',
    name: 'userWxList',
    component: () => import(/* webpackChunkName: "about" */ '../views/UserWxList/UserWxList.vue')
  }
  ,
  {
    //企业微信合同列表
    path: '/cbiWxList',
    name: 'cbiWxList',
    component: () => import(/* webpackChunkName: "about" */ '../views/CbiWxList/index.vue')
  }
  ,
  {
    //企业微信离职申请列表
    path: '/dimissionWxList',
    name: 'dimissionWxList',
    component: () => import(/* webpackChunkName: "about" */ '../views/DimissionWxList/DimissionWxList.vue')
  }
  ,
  {
    //述职评分
    path: '/reportingGrade',
    name: 'reportingGrade',
    component: () => import(/* webpackChunkName: "about" */ '../views/ReportingGrade/index.vue')
  }
  ,
  {
    //请假审批
    path: '/leaveApproval',
    name: 'leaveApproval',
    component: () => import(/* webpackChunkName: "about" */ '../views/LeaveApproval/index.vue')
  }
  ,
  {
    //需求全文搜索
    path: '/demandSearch',
    name: 'demandSearch',
    component: () => import(/* webpackChunkName: "about" */ '../views/DemandSearch/index.vue')
  }
  ,
  {
    //行程
    path: '/journeyAdd',
    name: 'journeyAdd',
    component: () => import(/* webpackChunkName: "about" */ '../views/Journey/JourneyAdd.vue')
  }
  ,
  {
    //行程
    path: '/journeyList',
    name: 'journeyList',
    component: () => import(/* webpackChunkName: "about" */ '../views/Journey/JourneyList.vue')
  }
  ,
  {
    //派遣单入住信息列表
    path: '/dispatchHotelList',
    name: 'dispatchHotelList',
    component: () => import(/* webpackChunkName: "about" */ '../views/DispatchHotel/DispatchHotelList.vue')
  }
  ,
  {
    //派遣单入住信息
    path: '/dispatchHotelDetail',
    name: 'dispatchHotelDetail',
    component: () => import(/* webpackChunkName: "about" */ '../views/DispatchHotel/DispatchHotelDetail.vue')
  }
  ,
  {
    //派遣单入住信息确认
    path: '/disptchHotelConfirm',
    name: 'disptchHotelConfirm',
    component: () => import(/* webpackChunkName: "about" */ '../views/DispatchHotel/DisptchHotelConfirm.vue')
  }
  ,
  {
    //派遣单
    path: '/disptchTaskList',
    name: 'disptchTaskList',
    component: () => import(/* webpackChunkName: "about" */ '../views/DisptchTask/DisptchTaskList.vue')
  }
  ,
  {
    //派遣单编辑
    path: '/disptchTaskEdit',
    name: 'disptchTaskEdit',
    component: () => import(/* webpackChunkName: "about" */ '../views/DisptchTask/DisptchTaskEdit.vue')
  },
  {
    //安装许可证列表
    path: '/productLicenseList',
    name: 'productLicenseList',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductLicense/ProductLiceneList.vue')
  },
  {
    //安装许可证单条审批界面
    path: '/productLicense',
    name: 'productLicense',
    component: () => import(/* webpackChunkName: "about" */ '../views/ProductLicense/ProductLicene.vue')
  },
  {
    //安装许可证单条审批界面
    path: '/contractRelatedBusiness',
    name: 'contractRelatedBusiness',
    component: () => import(/* webpackChunkName: "about" */ '../views/ContractRelatedBusiness/index.vue')
  },
  {
    //简历信息录入界面
    path: '/ResumeInfoAdd',
    name: 'ResumeInfoAdd',
    component: () => import(/* webpackChunkName: "about" */ '../views/ResumeInfo/ResumeInfoAdd.vue')
  },
  {
    //ocr文件对比
    path: '/fileOcrDiff',
    name: 'fileOcrDiff',
    component: () => import(/* webpackChunkName: "about" */ '../views/fileOcrDiff/index.vue')
  },
  
]

const router = createRouter({
  history: createWebHashHistory(process.env.BASE_URL),
  routes
})

export default router
